import { put, takeLatest, all, call } from 'redux-saga/effects';
import { GET_USERS_DATA, GET_ROLES_DATA } from 'constants/index';
import { tableGetSuccess, rolesGetSuccess } from 'actions/table'
import API from 'API'


function* getUsers() {
    try {
        const response = yield call(API.getUsers);
        yield put(tableGetSuccess(response));
    }catch (e) {
    }
}

function* getRoles() {
    try {
        const response = yield call(API.getRoles);
        yield put(rolesGetSuccess(response));
    } catch (e) {
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_USERS_DATA, getUsers),
        takeLatest(GET_ROLES_DATA, getRoles),
    ]);
}

export default Saga;
