import { takeLatest, all, call, put } from 'redux-saga/effects';
import { GET_CONTACTS_USER_REQUEST} from 'constants/index';
import { getContactsSuccess } from 'actions/contacts'
import API from 'API'

function* getContacts({ payload }) {
    try {
        const response = yield call(API.getContacts, { ...payload });
        yield put(getContactsSuccess(response));
    }catch (e) {
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_CONTACTS_USER_REQUEST, getContacts),
    ]);
}

export default Saga;
