import React, { useEffect, useMemo, useRef} from 'react'
import Parallax from 'parallax-js';
import { isEmpty } from 'lodash';

import classes from 'assets/sass/style.module.scss';
import HeaderComponent from 'containers/Header';
import {
    ErrorImage, ParallaxTriangleBig, ParallaxFour, ParallaxZero, ParallaxLineSmall
} from 'components/FlowImages';
import { handleLogout } from 'utils/function';
import ContextWorker from '../../contextWorker'
import parseTokens from "../../utils/parseToken";
import {regionConfigValue} from "../../utils/regionConfigValue";
import {RegionNotFound} from "../Region/RegionNotFound";

const NotFound = () => {

    ContextWorker();
    const parallaxRef = useRef(null);

    useEffect(() => {
        if(window.innerWidth > 992) {
            if (parallaxRef?.current) {
                new Parallax(parallaxRef?.current, {relativeInput: true});
            }
        }
    }, [parallaxRef]);

    const parseToken = parseTokens();
    const config = regionConfigValue();

    const isRegion = useMemo(() => {
        const emptyTokenAndServiceRegion = isEmpty(parseToken) && (window.location.origin !== config?.SERVICE_URL);
        const tokenRegion = !isEmpty(parseToken?.rgn) && parseToken?.rgn !== "77";

        return emptyTokenAndServiceRegion || tokenRegion;

    }, [parseToken, config]);

    const getButton = () => {
        return (
            <div onClick={() => handleLogout()} className={classes['btn-white']}>
                <i className={classes['arrow-btn-left']} />
                Перейти на главную
            </div>
        )
    }

    if (window.location.pathname.includes('user_not_found')) {
        let date = new Date(new Date().getTime() + 60 * 1000);
        const { COOKIE_DOMAIN } = regionConfigValue();
        document.cookie = `user_not_found=show; domain=${COOKIE_DOMAIN}; path=/; expires=${new Date(date.getTime() + 3600).toUTCString()}; secure`;
        handleLogout()

        return <div />
    }


    if (isRegion) {
        return (<RegionNotFound config={config} />)
    }

    return ( <div className={classes['site_wrapper']}>
        <HeaderComponent/>
        <div className={classes['main-container']}>
            <section className={classes['sec-error']}>
                <div className={classes['sec-error_inner']}>
                    <div className={classes['sec-error_info']}>
                        <div className={classes['sec-error_title']}>
                            Такую страницу<br/>
                            пока не изобрели
                        </div>
                        <div className={classes['sec-error_action']}>
                            {getButton()}
                        </div>
                    </div>
                    <div className={classes['sec-error_img']}>
                        <img src={ErrorImage} alt="error"/>
                    </div>
                </div>
                <div className={classes['sec-error_parallax']}>
                    <div data-relative-input="true" ref={parallaxRef} className={classes['parallax__list']}>
                        <div data-depth="0.4" className={`${classes['parallax_item']} ${classes['el-1']}`}>
                            <img src={ParallaxTriangleBig} alt=""/>
                        </div>
                        <div data-depth="0.2" className={`${classes['parallax_item']} ${classes['el-3']}`}>
                            <img src={ParallaxFour} alt=""/>
                        </div>
                        <div data-depth="0.1" className={`${classes['parallax_item']} ${classes['el-4']}`}>
                            <img src={ParallaxZero} alt=""/>
                        </div>
                        <div data-depth="0.3" className={`${classes['parallax_item']} ${classes['el-5']}`}>
                            <img src={ParallaxFour} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={classes['sec-error_mobile-line']}>
                    <img src={ParallaxLineSmall} alt="line"/>
                </div>
            </section>
        </div>
    </div>)
}

export default NotFound;
