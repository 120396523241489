import { regionConfigValue } from './regionConfigValue'
import { isEmpty } from 'lodash';
import parseTokens from "./parseToken";
import {getCookie} from "./cookie";

/**
 * Логаут локальной авторизации и возврат на главную страницу
 * @param configSUDIRURL
 * @constructor
 */
export const SUDIRLogout = (configSUDIRURL) => {
    window.location = `${configSUDIRURL}/sps/login/logout?aupd=99&post_logout_redirect_uri=${window.location.origin}/`;
}

/**
 * Логаут регионов
 */
export const regionLogout = () => {
    const { ESIA_CLIENT_ID, ESIA_URL, SERVICE_URL } = regionConfigValue();
    window.location = `${ESIA_URL}/idp/ext/Logout?client_id=${ESIA_CLIENT_ID}&redirect_url=${SERVICE_URL}`;
}

/**
 * Логаут пользователя - вызывается как триггером на клик, так и в колбэках
 * @param isV3
 */
export const handleLogout = (isV3) => {
    /**
     * Если логаут локальный на v3
     */
    if (isV3) {
        window.location = `${window.location.origin}/v3/auth/logout`;
    } else {
        /**
         * Иначе парсим токен на регион
         * @type {any}
         */
        const parseToken = parseTokens();
        const { COOKIE_DOMAIN, SUDIR_URL } = regionConfigValue();
        const contur = getCookie('auth');
        document.cookie = `auth= ; domain=${COOKIE_DOMAIN}; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT; secure`;

        if (!isEmpty(parseToken)) {

            if (isEmpty(parseToken?.rgn) || parseToken?.rgn === "77") {
                SUDIRLogout(SUDIR_URL);
            } else {
                regionLogout();
            }
        } else if (contur === 'sudir'){
            SUDIRLogout(SUDIR_URL);
        } else if (contur === 'esia') {
            regionLogout();
        } else {
            window.location = `/`;
        }
    }
}


export const rus_to_latin = ( str ) => {

    var ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
    }, n_str = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i').replace(/ /g, '.')

    for ( var i = 0; i < str.length; ++i ) {
        n_str.push(
            ru[ str[i] ]
            || ru[ str[i].toLowerCase() ] == undefined && str[i]    // eslint-disable-line
            || ru[ str[i].toLowerCase() ].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
        );
    }

    return n_str.join('').toLowerCase();
}

export const JsonParse = (body) => {
    try {
       return JSON.parse(body)
    }catch (e) {
        return {}
    }
}

export const sleep = (ms = 2000) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
